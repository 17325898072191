import './App.css';
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import MonthlySavings from "./MonthlySavings"
import Budget from "./Budget"
import RetireAmount from "./RetireAmount"
import TermsOfService from "./TermsOfService"
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useCookies } from 'react-cookie'


import ReactGA from "react-ga4";
ReactGA.initialize("G-WTTDFKNBQG");
ReactGA.send("pageview");


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://fitools.org/">
        Fitools.org
      </Link>{' '}
      {new Date().getFullYear()}
      {'.  '}
      <Link color="inherit" href="https://fitools.org/terms">
        Terms of Service
      </Link>{' '}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 2),
  },
  heroButtons: {
    marginTop: theme.spacing(2),
  },
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  paper: {
    height: '100%'
  },
  buttonPadding: {    
	  marginTop: theme.spacing (4)
  },
}));

function App() {
  const [cookies, setCookie] = useCookies(['terms_agreed']);
  let agree_terms = cookies['agree_terms'];
  if (agree_terms === "True") {
    agree_terms = false;
  }
  else {
    agree_terms = true;
  }
  const [state, setState] = React.useState({
    drawerOpen: agree_terms
  });

  const toggleDrawer = () =>  {
	  setState({ ...state, drawerOpen: false });
	  let expires = new Date()
          expires.setTime(expires.getTime() + (1000000))
	  setCookie('agree_terms', 'True', { path: '/',  expires})

  };
  const classes = useStyles();

  return (
   <Router>
    <React.Fragment>
       <CssBaseline />
       {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography component="h4" variant="h4" align="center" color="textPrimary" gutterBottom>
              Monthly Savings Calculator
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
              A simple tool to calculator to determine how much save per month to reach a savings goal.
            </Typography>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
            <div>
            <Switch>
              <Route path="/month-savings">
                  <MonthlySavings />
              </Route>
              <Route path="/budget">
                  <Budget />
              </Route>
              <Route path="/retire">
                   <RetireAmount />
              </Route>
              <Route path="/terms">
                   <TermsOfService />
              </Route>
	      <Route path="/">
                   <MonthlySavings />
              </Route>
	     </Switch>

            </div>
        </Container>
        <footer className={classes.footer}>
           <Typography variant="h6" align="center" gutterBottom>
                Simple Financial Tools
           </Typography>
           <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
           </Typography>
           <Copyright />
         </footer>
	   <Drawer open={state.drawerOpen} anchor={"bottom"}>
	    <Box paddingTop={1}  height={100}>
	     <Paper paddingTop={2} paddingBottom={2} elevation={3} className={classes.paper}>
	       <Grid container spacing={2}>
	         <Grid xs={8}>
	             <Typography variant="body2" color="textSecondary" align="center">
	                 Please click on this button to accept the{' '} 
                               <Link color="inherit" href="https://fitools.org/terms">terms of service</Link>{' '}
	                 before you use this website. 
	             </Typography>
	         </Grid>
	         <Grid xs={4}>
	          <Button className={classes.buttonPadding} 
	                  variant="contained"
	                  size="small"
	                  onClick={toggleDrawer}>
	              Accept Terms of Service
	          </Button>
	         </Grid>
	        </Grid>
	     </Paper>
	     </Box>
           </Drawer>
        
    </React.Fragment>
    </Router>
  );
}

export default App;
