import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import {useState} from "react";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';


const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  table: {
    minWidth: 650,
   },
  form: {
     width: '100%', // Fix IE 11 issue.
     marginTop: theme.spacing(3),
   },
   submit: {
     margin: theme.spacing(3, 0, 2),
    },
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};



function RetireAmount() {

  const classes = useStyles();

  const [values, setValues] = useState({
    incomeRequired: '',
    lifeExpectancy: '',
    realNominal: false,
    ageRetire: '',
    otherMonthlyIncome: '',
    data: [],
    monthlySavings: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeSlide = (event) => {
   setValues({ ...values, [event.target.name]: event.target.checked });
   console.log(values);
  };


  function handleClick () {
      const data = {
          income_required: values.incomeRequired,
          life_expectancy: values.lifeExpectancy,
          age_retire: values.ageRetire,
          other_monthly_income: values.otherMonthlyIncome,
          real: values.realNominal
        };
         axios.get("/data/retire", {params: data})
              .then( (response) => {
                   setValues({...values, 'savingsNeeded': response.data.month_savings, 'data':response.data.cash_flow})
               });
   }


  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Income Required"
              variant="outlined"
              value={values.incomeRequired}
              onChange={handleChange}
              name="incomeRequired"
              id="income-required"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={12}>
              <TextField
                 id="age-retire"
                 label="Retiring Age"
                 name="ageRetire"
                 onChange={handleChange}
                 variant="outlined"
              />
          </Grid>
          <Grid item xs={12}>
              <TextField
                label="Life Expectancy"
                variant="outlined"
                onChange={handleChange}
                name="lifeExpectancy"
                id="life-expectancy"
              />
        </Grid>
        <Grid item xs={12}>
            <TextField
              label="Other Monthly Income"
              variant="outlined"
              value={values.otherMonthlyIncome}
              onChange={handleChange}
              name="otherMonthlyIncome"
              id="other-monthly-income"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
      </Grid>
        <Grid item xs={12}>
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Nominal</Grid>
            <Grid item>
              <AntSwitch onChange={handleChangeSlide} name="realNominal" />
            </Grid>
            <Grid item>Real</Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={() => handleClick()}>
                 Caculate!
            </Button>
        </Grid>
       </Grid>
      </form>
      <Grid container spacing={4}>
          <Grid item xs={6} justify="center">
          {values.monthlySavings ? (
             <Card style={{backgroundColor: "#ffcccb"}}>
                <CardContent>
                 <strong> Monthly Savings Required:
                 <NumberFormat value={values.monthlySavings}
                               decimalScale={2}
                               displayType={'text'}
                               thousandSeparator={true} prefix={'$'}
                 /> </strong>
                 </CardContent>
             </Card>
           ) : ( <span></span>)
           }

          </Grid>
          <Grid item xs={12}>
              <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Year</TableCell>
                    <TableCell align="left">Savings (Jan 1)</TableCell>
                    <TableCell align="left">Annual Expenses</TableCell>
                    <TableCell align="left">Annual Investment Return</TableCell>
                    <TableCell align="left">Savings (Dec 31)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {values.data.map((row) => (
                    <TableRow key={row[0]}>
                      <TableCell component="th" scope="row" align="left">
                        {row[0]}
                      </TableCell>
                      <TableCell align="left">
                          <NumberFormat value={row[1]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                      </TableCell>
                      <TableCell align="left">
                           <NumberFormat value={row[2]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                      </TableCell>
                      <TableCell align="left">
                           <NumberFormat value={row[3]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                      </TableCell>
                      <TableCell align="left">
                           <NumberFormat value={row[4]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
    </div>
  );
}

export default RetireAmount;
