import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {useState} from "react";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  table: {
    minWidth: 650,
   },
  form: {
     width: '100%', // Fix IE 11 issue.
     marginTop: theme.spacing(3),
   },
   submit: {
     margin: theme.spacing(3, 0, 2),
    },
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};



function Budget() {

  const classes = useStyles();

  const [values, setValues] = useState({
    currentMonthlyIncome: 0,
    propertyTax: 0,
    rentMortgage: 0,
    carExpense: 0,
    cellPhone: 0,
    leftOver: 0,
    groceries: 0,
    childCare: 0,
    cableInternet: 0,
    water: 0,
    electricity: 0,
    retirementSavings: 0,
    childCollegeSavings: 0,
    entertainment: 0,
    subscriptions: 0,
    wardrobeUpkeep: 0,
    houseUpkeep: 0,
    homeInsurance: 0,
    petCare: 0,
    otherSavings: 0,
    eatingOut: 0,
    takeOut: 0,
    carInsurance: 0,
    gas: 0,
    otherTransportation: 0,
    otherDebtPayments: 0,
    other: 0,
    memberships: 0
  });

  const handleChange = (event) => {
    const newVals = {
      ...values,
      [event.target.name]: parseFloat(event.target.value),
      ['currentMonthlyIncome']: 0,
      ['leftOver']: 0
    };
    let sum = 0;
    for (let key in newVals) {
        sum += newVals[key];
    }
    var currentMI = values.currentMonthlyIncome;
    if (event.target.name == "currentMonthlyIncome") {
      currentMI = parseFloat(event.target.value);
    }
    var leftOver = currentMI - sum;
    setValues({...values, [event.target.name]: parseFloat(event.target.value), leftOver: leftOver })
  };

  function createData(title, name, value) {
    return { title, name, value };
  }
 const expenses = [
    createData("Home Expenses", "DIVIDER", ''),
    createData("Rent/Mortgage", "rentMortgage", ''),
    createData("Property Tax", "propertyTax", ''),
    createData("House Upkeep", "houseUpkeep", ''),
    createData("Home Insurance", "homeInsurance", ''),
    createData("Transportation", "DIVIDER", ''),
    createData("Car Expense", "carExpense", ''),
    createData("Car Insurance", "carInsurance", ''),
    createData("Gas", "gas", ''),
    createData("Other transportation", "otherTransportation", ''),
    createData("Utilities", "DIVIDER", ''),
    createData("Cable/Internet", "cableInternet", ''),
    createData("Water", "water", ''),
    createData("Electricity", "electricity", ''),
    createData("Cell Phone", "cellPhone", ''),
    createData("Savings", "DIVIDER", ''),
    createData("Retirement Savings", "retirementSavings", ''),
    createData("Child College Savings", "childCollegeSavings", ''),
    createData("Other Savings", "otherSavings", ''),
    createData("Food", "DIVIDER", ''),
    createData("Groceries", "groceries", ''),
    createData("Eating Out", "eatingOut", ''),
    createData("Take Out", "takeOut", ''),
    createData("Other", "DIVIDER", ''),
    createData("Child Care", "childCare", ''),
    createData("Life Insurance", "lifeInsurance", ''),
    createData("Entertainment", "entertainment", ''),
    createData("Subscriptions", "subscriptions", ''),
    createData("Wardrope Upkeep", "wardrobeUpkeep", ''),
    createData("Pet Care", "petCare", ''),
    createData("Vacations", "vacations", ''),
    createData("Other Debt Payments", "otherDebtPayments", ''),
    createData("Other", "other", ''),
    createData("Memberships", "memberships", '')

 ]
  function renderExpense (expense) {

    if (expense.name == "DIVIDER") {

      return (
        <Grid item xs={12}>
            <h4>{expense.title}</h4>
        </Grid>
      )
    }
    return (
    <Grid item xs={4}>
        <TextField
           id={expense.name}
           label={expense.title}
           name={expense.name}
           onChange={handleChange}
           variant="outlined"
           InputProps={{
             inputComponent: NumberFormatCustom,
           }}
        />
    </Grid>
            )

  }
  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <Grid container spacing={2}>
            <Grid item xs={12}>
              <h2>Income</h2>
            </Grid>
            <Grid item xs={12}>
                <TextField
                   id="current-month-income"
                   label="Current Monthly Income"
                   name="currentMonthlyIncome"
                   onChange={handleChange}
                   variant="outlined"
                   InputProps={{
                     inputComponent: NumberFormatCustom,
                   }}
                />
            </Grid>
            <Grid item xs={12}>
              <h2>Expenses</h2>
            </Grid>
            {expenses.map((expense) => (renderExpense(expense)))}
        </Grid>
      </form>
      <Grid container spacing={4}>
          <Grid item xs={6} justify="center">
             <Card style={{backgroundColor: "#ffcccb"}}>
                <CardContent>
                 <strong> Savings:
                 <NumberFormat value={values.leftOver}
                               decimalScale={2}
                               displayType={'text'}
                               thousandSeparator={true} prefix={'$'}
                 /> </strong>
                 </CardContent>
             </Card>
          </Grid>
        </Grid>
    </div>
  );
}

export default Budget;
