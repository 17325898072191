import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import {useState} from "react";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@material-ui/core/IconButton';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import CardContent from '@material-ui/core/CardContent';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {Adsense} from '@ctrl/react-adsense';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  table: {
    minWidth: 650,
   },
  form: {
     width: '100%', // Fix IE 11 issue.
     marginTop: theme.spacing(3),
   },
   submit: {
     margin: theme.spacing(3, 0, 2),
    },
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


function NumberFormatCustomPerc(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatCustomPerc.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const currentSavingsText = `
Input the amount that you have already saved towards your goal.
`;

const yearsOfSavingsText = `
Input the number of years that you will be saving towards your goal.
`;

const savingsGoalText = `
Input the amount that you want to save at the conclusion of the years.
`;

const investmentReturnText = `
Input the amount of annual return that you expect to receive on your savings.
As a point of reference, the S&P 500 has returned 9% annually over the last 30 years.
`;

const annualInflationText = `
Input the expected inflation over the savings period.  Over the last 30 years in the
United States, annual inflation has been approximately 3%.
`;

const growGoalText = `
If you turn this option on, this will increase your savings goal by the annual rate of
inflation each year.  For example, if your saving to buy a house in five years, and you
are savings $10,000, and the rate of inflation is 3%, then this will adjust your savings
goal to $11,593. You should not select this both option and the next option (real return).
`;

const growGoalLabel = (
	<Box>
	         <span>Grow Goal (increases savings goal by inflation)</span>
	         <Tooltip title={growGoalText}>
                   <IconButton>
                     <HelpIcon />
                   </IconButton>
                 </Tooltip>
	</Box>
);

const realReturnText = `
If you turn this option on, it will reduce your investment rate of return by the inflation
rate, so that the new rate of return will be a real rate of return.  You should not select
this option and the previous option (grow the savings goal).
`;

const realReturnLabel = (
	<Box>
	         <span>Real Return (subtracts inflation by investment return)</span>
	         <Tooltip title={realReturnText}>
                   <IconButton>
                     <HelpIcon />
                   </IconButton>
                 </Tooltip>
	</Box>
);



function MonthlySavings() {

  const classes = useStyles();

  const [values, setValues] = useState({
    currentSavings: '',
    savingsGoal: '',
    years: '',
    data: [],
    annualReturn: '',
    annualInflation: '',
    monthlySavings: '',
    nominalInflation: false,
    growGoal: false
  });

  const handleChange = (event) => {
    console.log(event.target.name);
    console.log(event.target.value);
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeChecked = (event) => {
    console.log(event.target.name);
    console.log(event.target.checked);
    setValues({
      ...values,
      [event.target.name]: event.target.checked,
    });
  };

  function handleClick () {
      const data = {
          current_savings: values.currentSavings,
          savings_goal: values.savingsGoal,
          years: values.years,
	  investment_return: values.annualReturn,
	  inflation: values.annualInflation,
	  grow_goal: values.growGoal,
	  nominal_inflation: values.nominalInflation
        };
         axios.get("/data", {params: data})
              .then( (response) => {
                   setValues({
	               ...values,
		       'monthlySavings': response.data.month_savings,
		       'data':response.data.cash_flow})
               });
   }


  return (
    <div>
      <form className={classes.form} noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Current Savings"
              variant="outlined"
              value={values.currentSavings}
              onChange={handleChange}
              name="currentSavings"
              id="current-savings"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
	    <Tooltip title={currentSavingsText}>
             <IconButton>
               <HelpIcon />
             </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
              <TextField
                 id="years-savings"
                 label="Years of Savings"
                 name="years"
                 onChange={handleChange}
                 variant="outlined"
              />
	    <Tooltip title={yearsOfSavingsText}>
             <IconButton>
               <HelpIcon />
             </IconButton>
            </Tooltip>
  
          </Grid>
          <Grid item xs={12}>
              <TextField
                label="Savings Goal"
                variant="outlined"
                value={values.savingsGoal}
                onChange={handleChange}
                name="savingsGoal"
                id="savings-goal"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
	    <Tooltip title={savingsGoalText}>
             <IconButton>
               <HelpIcon />
             </IconButton>
            </Tooltip>
  

        </Grid>
          <Grid item xs={12}>
              <TextField
                label="Investment Return"
                variant="outlined"
                value={values.annualReturn}
                onChange={handleChange}
                name="annualReturn"
                id="annual-return"
                InputProps={{
                  inputComponent: NumberFormatCustomPerc,
                }}
              />
	    <Tooltip title={investmentReturnText}>
             <IconButton>
               <HelpIcon />
             </IconButton>
            </Tooltip>
  

        </Grid>
           <Grid item xs={12}>
              <TextField
                label="Annual Inflation"
                variant="outlined"
                value={values.annualInflation}
                onChange={handleChange}
                name="annualInflation"
                id="annual-inflation"
                InputProps={{
                  inputComponent: NumberFormatCustomPerc,
                }}
              />
	    <Tooltip title={annualInflationText}>
             <IconButton>
               <HelpIcon />
             </IconButton>
            </Tooltip>
        </Grid>
 	<Grid item xs={12}>
           <FormControl component="fieldset" variant="standard">
              <FormGroup>
                 <FormControlLabel
                      control={
                        <Switch checked={values.growGoal} onChange={handleChangeChecked} name="growGoal" />
                      }
                      label={growGoalLabel}
	         />
                  <FormControlLabel
                      control={
                       <Switch checked={values.nominalInflation} onChange={handleChangeChecked} name="nominalInflation" />
                      }
                      label={realReturnLabel}
                  />
              </FormGroup>
            </FormControl>
	  </Grid>
        
	  <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={() => handleClick()}>
                 Caculate!
            </Button>
        </Grid>
       </Grid>
      </form>
      <Grid container spacing={4}>
          <Grid item xs={6} justify="center">
          {values.monthlySavings ? (
             <Card style={{backgroundColor: "#ffcccb"}}>
                <CardContent>
                 <strong> Monthly Savings Required:
                 <NumberFormat value={values.monthlySavings}
                               decimalScale={2}
                               displayType={'text'}
                               thousandSeparator={true} prefix={'$'}
                 /> </strong>
                 </CardContent>
             </Card>
           ) : ( <span></span>)
           }

          </Grid>
  <Grid item xs={12}>
              <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Year</TableCell>
                    <TableCell align="left">Savings (Jan 1)</TableCell>
                    <TableCell align="left">Savings (Dec 31)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {values.data.map((row) => (
                    <TableRow key={row[0]}>
                      <TableCell component="th" scope="row" align="left">
                        {row[0]}
                      </TableCell>
                      <TableCell align="left">
                          <NumberFormat value={row[1]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                      </TableCell>
                      <TableCell align="left">
                           <NumberFormat value={row[2]} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
	<Adsense
           client="8525126609082316"
           slot="7259870550"
           style={{ width: 500, height: 300 }}
           format=""
         />
    </div>
  );
}

export default MonthlySavings;
