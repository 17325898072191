import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  table: {
    minWidth: 650,
   },
  form: {
     width: '100%', // Fix IE 11 issue.
     marginTop: theme.spacing(3),
   },
   submit: {
     margin: theme.spacing(3, 0, 2),
    },
  },
}));

function TermsOfService() {


  return (
    <div>
	  <h1> Terms of Service </h1>

<h3>Last updated: December 15, 2022 </h3>

	  <p>Please read these Terms of Service fully and carefully before accessing or using fitools.org (the "Site"), any web, mobile, desktop, or other software applications provided by FiTools. (the "Applications"), and the algorithmic services, features, content, or applications (together with the Site and the Applications, the "Services") offered by FiTools.</p>
<p>These Terms of Service set forth the legally binding terms and conditions for your personal access to and use of the Services.</p>

	  <p>By clicking "Accept" when notified of these Terms of Service or by creating an Account on, accessing, or using the Services, you agree that you will be bound by these Terms of Service and all other operating rules, policies, and procedures that may be published from time to time on the Services by us, each of which is incorporated by reference and each of which may be updated from time to time by us.</p>

<p>By accepting these Terms of Service, you agree that the Terms of Service constitute a binding contract, effective as of the date of first acceptance by you, between each of FiTools, (collectively herein, "FiTools", "we", "us", or "our") and you (each a "Party" to the contract and collectively, the "Parties").
Certain of the Services may be subject to additional or supplemental terms and conditions specified by us from time to time; your use of such Services is subject to those additional terms and conditions, which are incorporated into these Terms of Service by this reference. Unless indicated otherwise, if there is a conflict between these Terms of Service and the additional terms, the additional terms will govern with respect to that conflict.</p>
<p>Please also note that these Terms of Service contain a mandatory arbitration provision that requires the use of arbitration on an individual basis and limits the remedies available to you in the event of certain disputes. If you do not agree to these Terms of Service, including the mandatory arbitration provision and class action waiver, you must cease using the Services.</p>
<p>1.	Use Rules
While we may provide information regarding use of the Services from time to time, it is solely your responsibility to ensure that you understand, acknowledge, and are in compliance with the Qualification Requirements (defined below in Section 2), Healthcare Rules (defined below in Section 4), the Other Rules of Conduct (defined below in Section 5), other policies regarding use in these Terms of Service, and any local, state, federal, or international laws, statutes, rules, regulations, industry standards, or best practices that may in any way regulate your use of Services (collectively, "Use Rules").</p>
<p>2.	Qualification Requirements</p>
<p>a.	In order to use the Services, you represent and certify that you meet all of the following qualification requirements: (i) You are at least 16 years of age.</p>
<p>b.	By accepting these Terms of Service, you represent and warrant that you meet all of the qualification requirements set forth above and are thereby authorized to use the Services. If you do not or cease to meet said qualification requirements, you must not, under any circumstances or for any reason, use the Services and must cease to be a user.</p>
<p>c.	We may, in our sole discretion, refuse to offer the Services to any person and change qualification requirements at any time. Further, the Services are offered only for your personal use, and not for the use or benefit of any third party or any legal entity.</p>
<p>3.	Content Terms and Definitions</p>
<p>a.	For purposes of these Terms of Service, the term "Content" includes all content generated, provided, or otherwise made accessible on or through the Services and includes, without limitation, information, data, text, photographs, images, illustrations, videos, audio clips, posts, comments, questions, answers, software, scripts, graphics, and interactive features generated, provided, or otherwise made accessible on or through the Services. For the purposes of these Terms of Service, Content also includes all User Content (as defined below). We reserve the right to, but do not have any obligation to: (i) remove, edit, or modify any Content in our sole discretion, at any time, without notice to you and for any reason (including, but not limited to, upon receipt of claims or allegations from third parties or authorities relating to such Content or if we are concerned that you may have violated these Terms of Service), or for no reason at all; and (ii) to remove or block any Content from the Services.</p>
<p>b.	All Content added, created, uploaded, submitted, distributed, or posted to the Services by users (collectively, "User Content"), whether publicly posted or privately transmitted, is the sole responsibility of the user who originated such User Content, and we will not be liable for any errors or omissions. You agree that User Content is submitted voluntarily and with your express consent.</p>
<p>c.	You acknowledge and agree that all User Content submitted by you ("Your Content") may be associated, displayed, or presented with your name and/or identity. Some aspects of the Services may permit you to submit User Content to the Services that is only accessible to you and/or users you have explicitly allowed access ("Your Private Content"). We are not responsible for storing, backing up, or preventing loss of any of Your Content.</p>
<p>4.	Other Rules of Conduct</p>
<p>a.	As a condition of use, you agree to not use the Services for any purpose that is prohibited by these Terms of Service, prohibited by applicable laws, or otherwise contrary to the intended purpose of the Services. You are responsible for all your activity in connection with the Services. You shall not enable others to violate any of these Terms of Service.</p>
<p>b.	The Services may contain Content specifically provided by us, our partners, or our users and such Content is protected by copyrights, trademarks, service marks, patents, trade secrets, or other proprietary rights and laws. You shall abide by and maintain all copyright notices, trademark rules, information, and restrictions contained in any Content accessed through the Services.</p>
<p>c.	You shall not (and shall not permit any third party to) either (a) take any action or (b) upload, download, post, submit, or otherwise distribute or facilitate distribution of any Content on or through the Service, including without limitation any of Your Content, that:</p>
<p>i.	Infringes any patent, trademark, trade secret, copyright, right of publicity, or other right of any other person or entity or violates any law or contractual duty;</p>
<p>ii.	Is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, misleading, fraudulent, invasive of another's privacy, tortious, obscene, vulgar, offensive, profane, contains or depicts sexual activity, or is otherwise inappropriate, as determined by us in our sole discretion;</p>
<p>iii.	Constitutes unauthorized or unsolicited advertising, junk, spamming, or bulk e-mail;</p>
<p>iv.	Contains software viruses or any other computer codes, files, or programs that are designed or intended to disrupt, damage, limit, or interfere with the proper function of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any system, data, password, or other information of ours or of any third party;</p>
<p>v.	Impersonates any person or entity, including any of our employees or representatives;</p>
<p>vi.	Includes anyone's identification documents or sensitive financial information;</p>
<p>vii.	Restricts or inhibits any other person from using or enjoying our Services, or that may expose us or others to any harm or liability of any type; or</p>
<p>d.	You shall not: (i) take any action that imposes or may impose (as determined by us in our sole discretion) an unreasonable or disproportionately large load on our (or our third party providers') infrastructure; (ii) interfere or attempt to interfere with the proper working of the Services or any activities conducted on the Services; (iii) bypass, circumvent, or attempt to bypass or circumvent any measures we may use to prevent or restrict access to the Services (or other accounts, computer systems, or networks connected to the Services); (iv) run any form of auto-responder or "spam" on the Services; (v) use manual or automated software, devices, or other processes to "crawl" or "spider" any page of the Site; (vi) download or export any Content from the Services using tools, software, or methods not explicitly provisioned by the Services, including but not limited to "harvesting" or "scraping" such Content; or (vii) otherwise take any action in violation of our guidelines and policies.</p>
<p>e.	You shall not (directly or indirectly): (i) decipher, decompile, disassemble, reverse engineer, or otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the Services (including without limitation any application), except to the limited extent applicable laws specifically prohibit such restriction, (ii) modify, translate, or otherwise create derivative works of any part of the Services, or (iii) copy, rent, lease, distribute, or otherwise transfer any of the rights that you receive hereunder. You shall abide by all applicable local, state, national and international laws, rules, and regulations.</p>
<p>f.	We also reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to (i) satisfy any applicable law, regulation, legal process, or governmental request, (ii) enforce these Terms of Service, including investigation of potential violations hereof, (iii) detect, prevent, or otherwise address fraud, security or technical issues, (iv) respond to user support requests, or (v) protect the rights, property or safety of us, our users and the public.</p>
<p>g.	You shall not create or attempt to create a substitute or similar service or product through use of or access to the Services or proprietary information related thereto. You shall not merge the Services with other software.</p>
<p>h.	You shall not use the Services to advertise or promote yourself.</p>
<p>i.	You shall not use FiTools's trademarks, trade dress, trade names, logos, icons, symbols, or other identifiers of FiTools without separate written consent of FiTools clearly permitting such use.</p>
<p>j.	You shall not intentionally gain unauthorized access to the Services, and if you do gain unauthorized access to the Services, you must cease unauthorized access to the Services immediately and give us notice on our website within twenty-four (24) hours.</p>
<p>k.	You shall not remove or alter any copyright notices or other notices included in the Services.i</p>
<p>l.	You shall not alter or modify another website, service, or product so as to falsely imply or represent that it is associated with us, unless separately and clearly authorized in writing.</p>
<p>5.	Registration</p>
<p>a.	Certain of the Services may allow you to register for a user account on the Services ("Account(s)"). You must provide accurate and complete information and keep Account information updated. You shall not: (i) select or use as a username a name, email, or phone number of another person with the intent to impersonate that person; (ii) use as a username a name subject to any rights of a person other than yourself without appropriate authorization; (iii) use, as a username, a name that is otherwise offensive, vulgar, or obscene; or (iv) use as a username a fictitious name or pseudonym. You are solely responsible for the activity that occurs on your Account, and for keeping your Account secure, including without limitation confidentially and securely maintaining your password and limiting access to your devices to prevent unauthorized use. You may not use another user's account or registration information for the Services without permission. You must notify us immediately on our website of any change in your qualification to use the Services (including any changes to or revocation of any licenses from state authorities), breach of security, or unauthorized use of your Account. You should never publish, distribute, or post login information for your Account. You shall have the ability to delete your Account, either through the Services or through a request made on our website.</p>
<p>b.	By creating an Account, you consent to our storage, control, processing, and all other use of the Account information you provide in connection with your Account, which is provided of your own volition to use the Services, consistent with our Privacy Policy and the intended function and use of the Services.</p>
<p>c.	By creating an Account, you also consent to receive electronic communications from FiTools (e.g., via email, SMS or other text messaging, or by posting notices to the Services). These communications may include notices about your Account (e.g., authentications, password changes, and other transactional information) and are part of your relationship with us. You agree that any notices, agreements, disclosures or other communications that we send to you electronically will satisfy any legal communication requirements, including but not limited to that such communications be in writing. You expressly consent to the use of information that you provide to us, including but not limited to your phone numbers, to contact you using automatic dialing systems in both audio and text formats. We may also send you promotional communications via email, including, but not limited to, newsletters and other news and information we think will be of interest. You may opt out of receiving these promotional emails at any time by following the unsubscribe instructions provided therein.
<p>6.	Privacy Policy</p>
<p>Please refer to our Privacy Policy (which is incorporated by reference as if fully set forth herein) for information about whether or how we collect, use, and disclose your information.</p>
<p>7.	Licenses</p>
<p>a.	License to use the Services. Subject to these Terms of Service, FiTools grants you a limited, revocable, non-exclusive, non-sublicensable, and non-transferable license to the Services and the Content (and other items displayed on the Services for download) solely for purposes of using the Services consistent with these Terms of Service. Use, reproduction, modification, distribution, or storage of any Content for purposes other than using the Services consistent with these Terms of Service is expressly prohibited without prior written permission from us.</p>
<p>b.	License to Your Content. By submitting Your Content (excluding Your Private Content) through the Services, you hereby grant us a worldwide, non-exclusive, perpetual, irrevocable, royalty-free, fully paid, sublicensable, and transferable license to use, edit, modify, truncate, aggregate, reproduce, distribute, prepare derivative works of, display, perform, and otherwise fully exploit Your Content in connection with the Services and our (and our successors' and assigns') businesses, including without limitation for promoting and redistributing part or all of the Services (and derivative works thereof) in any media formats and through any media channels (including, without limitation, third party websites and feeds), and including after termination of your Account or use of the Services. You also hereby grant each user of the Services and each enterprise subscribed to the Services a non-exclusive, perpetual license to access Your Content (excluding Your Private Content with respect to users that you have not explicitly allowed access) in the form available to such user or enterprise through the Services, and to use, edit, modify, reproduce, distribute, prepare derivative works of, display, and perform Your Content, including after termination of your Account or use of the Services.</p>
<p>c.	License to Your Private Content. By submitting Your Private Content through the Services, you thereby grants us a worldwide, non-exclusive, perpetual, royalty-free, fully paid, sublicensable, and transferable license to use, edit, modify, truncate, aggregate, reproduce, distribute, prepare derivative works of, display, and perform Your Private Content solely for the purpose of providing the Services to you.i</p>
<p>d.	License to feedback. You grant us a worldwide, non-exclusive, perpetual, irrevocable, royalty-free, fully paid, sublicensable, and transferable license to use, edit, modify, truncate, aggregate, reproduce, distribute, prepare derivative works of, display, perform, and otherwise fully exploit (including by incorporating into the Services) any suggestions, enhancement requests, recommendations or other feedback that you provide to us regarding the Services.</p>
<p>e.	Right to license. You represent and warrant that you have all rights to grant the license in this section to us without infringement or violation of any third party rights, including without limitation, any privacy rights, publicity rights, copyrights, trademarks, contract rights, or any other intellectual property or proprietary rights.</p>
<p>f.	Preservation of rights. The foregoing license grants to us and our users do not affect other ownership or license rights in Your Content or other intellectual property of yours, including your right to grant additional licenses to Your Content, provided that such other ownership or license rights in Your Content are not inconsistent with these Terms of Service.</p>
<p>8.	DMCA Copyright Policy</p>
<p>We have a policy of limiting access to our Services and terminating the accounts of users who infringe the intellectual property rights of others. If you believe that anything on our Services infringes any copyright that you own or control, please refer to our DMCA Copyright Policy (which is incorporated by reference as if fully set forth herein).</p>
<p>9.	Third Party Services</p>
<p>The Services may permit you to link to other websites, services, or resources on the Internet, and other websites, services or resources may contain links to the Services. Additionally, Content may contain links to other websites, services, or resources on the Internet. When you access third party resources on the Internet, you do so at your own risk. These other resources are not under our control, and you acknowledge that we are not responsible or liable for the content, functions, accuracy, legality, appropriateness or any other aspect of such websites or resources. The inclusion of any such link does not imply our endorsement or any association between us and their operators. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such website or resource.</p>
<p>10.	Termination</p>
<p>We may terminate your access to all or any part of the Services at any time, with or without cause, with or without notice, effective immediately, which may result in the forfeiture and destruction of all information associated with your membership. If you wish to terminate your Account, you may do so through a request made on our website. Any such termination shall immediately revoke the license granted pursuant to Section 9(a), and you shall immediately be prohibited from accessing or using the Services or Content for any reason. Any fees paid hereunder are non-refundable. All provisions of these Terms of Service which by their nature should survive termination shall survive termination, including but not limited to licenses of Your Content, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>
<p>11.	Release
To the fullest extent permitted by applicable law, you release FiTools and all other users of the Services from responsibility, liability, claims, causes of action, demands, expenses, and/or damages (actual and consequential) of every kind and nature, known and unknown (including, but not limited to, claims of negligence), arising out of or related to your use of and/or reliance on the Services, including but not limited to disputes between users and the acts or omissions of third parties.
<p>12.	Warranty Disclaimer</p>
<p>THE SERVICES AND CONTENT ARE PROVIDED "AS IS", "AS AVAILABLE", AND WITHOUT WARRANTY, REPRESENTATION, OR GUARANTEE OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. WE AND OUR DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, PARTNERS, AND CONTENT PROVIDERS DO NOT WARRANT THAT: (I) THE SERVICES WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (II) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (III) ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE SERVICES IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; (IV) ANY INFORMATION IS COMPLETE, ACCURATE, UP-TO-DATE, OR RELIABLE; (V) ANY PARTICULAR SERVICE, CONTENT, OR PRODUCT REFERRED TO IN THE SERVICES IS SAFE, APPROPRIATE, OR EFFECTIVE FOR YOUR AND/OR YOUR PATIENTS; (VI) THE RESULTS OF USING THE SERVICES WILL MEET YOUR AND/OR YOUR PATIENTS' REQUIREMENTS; (VII) USE OF THE SERVICES WILL COMPLY WITH ANY LAWS, REGULATIONS, RULES, POLICIES, REQUIREMENTS, QUALIFICATIONS, OR BEST PRACTICES, INCLUDING BUT NOT LIMITED TO PRIVACY LAWS, PROFESSIONAL LICENSURE, OR REIMBURSEMENT; (VIII) USE OF THE SERVICES WILL NOT RESULT IN ANY LEGAL DUTIES OR LIABILITY. YOUR USE OF THE SERVICES IS SOLELY AT YOUR OWN RISK. WE DO NOT GUARANTEE THAT ANY PARTICULAR CONTENT WILL BE MADE AVAILABLE THROUGH THE SERVICES.</p>
<p>13.	Indemnification</p>
<p>You shall defend, indemnify, and hold harmless us, our affiliates and each of our and our affiliates' respective officers, directors, employees, contractors, agents, suppliers, and representatives from all liabilities, claims, fees, costs, penalties or sanctions, expenses, and interest of any nature, including reasonable attorneys' fees, that arise from or relate to: (a) your use or misuse of, or access to, the Services or Content; (b) Your Content, including Your Content that is untrue, inaccurate, unreliable, fraudulent, misrepresentative, outdated, or that induced or was reasonably understood to induce reliance; (c) your violation of any privacy, professional, ethics, licensing, or consumer protection laws, rules, or regulations; (d) your misuse of anyone's private, proprietary, or confidential information; (e) infringement by you (or any third party using your Account or identity in the Services) of any intellectual property or other right of any person or entity; or (f) otherwise in violation of these Terms of Service in any way. We reserve the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will assist and cooperate with us in asserting any available defenses at your expense, including reasonable attorneys' fees incurred by us.</p>
<p>14.	Limitation of Liability</p>
<p>THE USE OF THE SERVICES IS ENTIRELY AT YOUR OWN RISK. IN NO EVENT SHALL WE, NOR OUR OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, AGENTS, PARTNERS, SUPPLIERS, CONTENT PROVIDERS, OR USERS BE LIABLE TO YOU UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE, OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SERVICES (I) FOR ANY LOST PROFITS, LOST REVENUE, LOSS OF GOODWILL, DATA LOSS, LOSS OF USE, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OTHER INTANGIBLE LOSSES, OR INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, COMPENSATORY, EXEMPLARY, RELIANCE, PUNITIVE, LIQUIDATED, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER (HOWEVER ARISING), (II) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE OF ORIGINATION), (III) FOR ANY PERSONAL INJURY OR HARM, INCLUDING DEATH, CAUSED BY YOUR USE OR MISUSE OF THE SERVICES, (IV) FOR ANY CLAIMS, DEMANDS, OR DAMAGES ARISING OUT OF OR RELATING TO ANY DISPUTE BETWEEN YOU AND ANY OTHER USER OF THE SERVICES, OR (V) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) OF THE GREATER OF (A) FEES PAID BY YOU FOR USE OF PARTICULAR SERVICES DURING THE IMMEDIATELY PREVIOUS THREE (3) MONTH PERIOD OR (B) $500.00. REMEDIES UNDER THESE TERMS OF SERVICE ARE EXCLUSIVE AND ARE LIMITED TO THOSE EXPRESSLY PROVIDED FOR IN THESE TERMS OF SERVICE. Some jurisdictions may not permit us to limit our liability in certain ways, so it is possible that some limitations will not apply to our agreement with you. In such events, the limitations will apply to the fullest extent permitted under applicable law.</p>
<p>15.	Dispute Resolution; Arbitrationi</p>
PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT REQUIRES YOU TO ARBITRATE CERTAIN DISPUTES AND CLAIMS WITH FITOOLS AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US.</p>
<p>a.	Except for any dispute in which either party seeks to bring an individual action in small claims court or seeks injunctive or other equitable relief for the alleged unlawful use of intellectual property, any claim or controversy arising out of or relating to these Terms of Service, or any breach thereof, or arising out of or relating to your access to or use of the Services (except such claims or controversies for which injunctive relief is available) ("Arbitrated Claims") shall be settled by arbitration administered by the American Arbitration Association (AAA) in accordance with its Commercial Arbitration Rules ("AAA Rules"), and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. The AAA Rules are available online at adr.org. You agree that, by accepting these Terms of Service, you and FiTools are each waiving the right to a trial by jury and the right to participate in a class action or any other type of representative proceeding in connection with Arbitrated Claims.</p>
<p>b.	You and FiTools agree that any claim or controversy arising out of or related to these Terms of Service or your access to or use of the Services is personal to you and FiTools, and that such claim or controversy will be resolved solely through individual arbitration and will not be brought as a class arbitration, class action or any other type of representative proceeding. You and FiTools agree that there will be no class arbitration or arbitration in which an individual attempts to resolve a claim or controversy as a representative of another individual or group of individuals. Further, you and FiTools agree that a claim or controversy cannot be brought as a class or other type of representative action, whether within or outside of arbitration, or on behalf of any other individual or group of individuals. The arbitrator does not have the power to vary these class action waiver provisions.</p>
<p>c.	These Terms of Use evidence a transaction in interstate commerce, and thus the Federal Arbitration Act governs the interpretation and enforcement of this Section. The arbitration shall be conducted in accordance with the AAA Rules.</p>
<p>d.	As limited by the FAA, these Terms of Service and the applicable AAA rules, the arbitrator will have the authority to grant any remedy that would otherwise be available in court; provided, however, that the arbitrator does not have the authority to conduct a class arbitration or a representative action, which is prohibited by these Terms of Service. The arbitrator may only conduct an individual arbitration and may not consolidate more than one individual's claims, preside over any type of class or representative proceeding or preside over any proceeding involving more than one individual. Notwithstanding any other provision of this Section 17, any and all issues relating to the scope, interpretation, and enforceability of the class action waiver provisions contained in this Section 17, are to be decided only by a court of competent jurisdiction, and not by the arbitrator.</p>
<p>e.	Unless you and FiTools expressly agree otherwise, any arbitration hearings will take place in San Francisco, California or New York, New York. If the claim or controversy is for $10,000 or less, you agree that FiTools may choose whether the arbitration will be conducted solely on the basis of documents submitted to the arbitrator(s), through a telephonic hearing or by an in-person hearing as established by the AAA Rules. Regardless of the manner in which the arbitration is conducted, the award of the arbitrator(s) shall be accompanied by a statement of the reasons upon which the award is based, and you and FiTools agree that any such award shall be enforceable in any court of competent jurisdiction. Any provision of applicable law notwithstanding, the arbitrator will not have authority to award damages, remedies or awards that conflict with these Terms of Service.</p>
<p>f.	Without limitation to the foregoing, you must bring any claims you may have against us within six (6) months of the date the claim arose or be forever barred.</p>

<p>16.	Governing Law and Jurisdiction</p>
These Terms of Service shall be governed by and construed in accordance with the laws of the State of Delaware, without regard to any conflict-of-laws provisions or rules.</p>
<p>17.	Modification</p>
<p>We reserve the right, in our sole discretion, to modify or replace any of these Terms of Service, or change, suspend, or discontinue the Services (including without limitation, the availability of any feature, database, or content) at any time by posting a notice through the Services or by sending you notice via e-mail, via the Services, or by another appropriate means of communication. We may also impose limits on certain features and services or restrict your access to parts or all of the Services without notice or liability. While we will provide notice of modifications to these Terms of Service, it is also your responsibility to take reasonable efforts to be aware of such modifications. Your continued use of the Services following notification of any modification to these Terms of Service shall constitute acceptance of those modifications, and such modifications shall apply to your continued use of the Services going forward. Your use of the Services is subject to the Terms of Service in effect at the time of such use.</p>
<p>18.	Miscellaneous</p>
<p>a.	Entire agreement and severability. These Terms of Service are the entire agreement between you and us with respect to the Services and supersede all prior or contemporaneous communications and proposals (whether oral, written or electronic) between you and us with respect to the Services. If any provision of these Terms of Service is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that these Terms of Service will otherwise remain in full force and effect and enforceable. The failure of either Party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder.</p>
<p>b.	Relationship of the parties. You and FiTools are independent contractors. These Terms of Service do not create a partnership, franchise, joint venture, agency, fiduciary or employment relationship between the Parties. You do not have any authority of any kind to bind us in any respect. There are no third party beneficiaries to the Terms of Service, unless expressly stated otherwise in the Terms of Service. We have no special relationship with or fiduciary duty to you.</p>
<p>c.	Force majeure. We shall not be liable for any failure or delay in the performance of our obligations hereunder where such failure results from any cause beyond our reasonable control, including, without limitation, mechanical, electronic or communications failure or degradation, denial-of-service attacks, a failure by a third party hosting provider or utility provider, strikes, shortages, riots, fires, acts of God, war, terrorism, and governmental action.</p>
<p>d.	Assignment. These Terms of Service are personal to you, and are not assignable, transferable or sublicensable by you. We may assign, transfer or delegate any of our rights and obligations hereunder without consent.</p>
<p>e.	Notices. Unless otherwise specified in these Terms of Service, all notices under these Terms of Service will be in writing. Notices for us shall be sent to us through our our website, and you must ensure written confirmation of receipt for notice to be effective. Notices for you shall be sent to your last known email address (or the email address of your successor, if any) and/or to an email address that would be reasonably likely to provide notice to you, and such notice shall be effective upon transmission.</p>
<p>f.	No waiver. Our failure to enforce any part of these Terms of Service shall not constitute a waiver of our right to later enforce that or any other part of these Terms of Service. Waiver of compliance in any particular instance does not mean that we will waive compliance in the future.</p>

<p>19.	Contact
You may contact us through our website.</p>

    </div>
  );
}

export default TermsOfService;
